import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api.safetelecom.net/api/clients?access=phones",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getAPI() {
    return apiClient.get();
  },
};
