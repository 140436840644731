var string = {
  to_hex(str) {
    var hash = 0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    var color = "";
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 255;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  },
};

export default string;
