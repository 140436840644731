<template>
  <div>
    <h1>Sales Per Month</h1>
    <v-app>
     <v-select
          :items="years_list"
          label="Year"
          outlined
          id="year_select"
          v-model.number="year"
        ></v-select>
    <label> {{year}}</label>
    <v-checkbox
      type="checkbox"
      id="vendor_Sort"
      label="Sort by vendor"
      v-model="vendor_Sort"
    />
    <canvas id="SalesPerMonth" ref="canvas"></canvas>
    </v-app>
  </div>
</template>

<script>
import apiClient from "@/services/ApiCall.js";
import moment from "moment";
import Chart from "chart.js";
import string from "@/services/String_to_hex.js";
var first_time_pinged_system = [];
var months_by_year = [];
var months_by_year_devices = [];
var chart_data = [];
var bar_chart = null;
var devices_info = {};

import SalesPerMonth from "@/services/BarChart.js";
export default {
  name: "SalesPerMonth",
  props: {
    msg: String,
  },
  data() {
    return {
      //vuetify
      years_list: [],
      //data
      year: 2017,
      vendor_Sort: false,
      json_data: null,
      first_time_pinged_system: first_time_pinged_system, //moment.js objects
      months_by_year: months_by_year,
      months_by_year_devices: months_by_year_devices, // [year{device:[month array]}]
      chart_data: chart_data,
      devices_info: devices_info,
      //Chart
      bar_chart: bar_chart,
      SalesPerMonth: SalesPerMonth,
    };
  },
  created() {
    console.log("in getMonthDataISH...");
    apiClient
      .getAPI()
      .then((response) => {
        this.json_data = response.data;
        console.log("test " + this.first_time_pinged_system.length);
        this.extract_date_info(this.json_data);
        months_by_year = this.month_data_by_year(this.first_time_pinged_system);
        devices_info = this.get_device_info(this.json_data);
        //console.log("m_load_D INFO! " + JSON.stringify(devices_info));
        SalesPerMonth.data.datasets[0].data =
          months_by_year[2017 - this.year];

        this.months_by_year_devices = this.month_data_by_year_by_device(
          this.json_data,
          devices_info
        );
        // this.months_by_year_devices.forEach(function (arrayItem) {
        //   console.log("m_load " + JSON.stringify(arrayItem));
        // });
      })
      .catch((error) => {
        console.log(error);
      });
      this.years_list = this.create_years_list();
  },

  mounted() {
    //Create Chart
    //console.log("spm2 " + SalesPerMonth.data.datasets[0].data);
    const ctx = document.getElementById("SalesPerMonth");
    bar_chart = new Chart(ctx, this.SalesPerMonth);
    bar_chart.update();
  },
  updated() {
    var vendor_sort = this.vendor_Sort;
    var months_by_year_devices_L = this.months_by_year_devices;
    // Update Chart based on year slider
    var year = this.year;
   // var select = document.getElementById("year_select");
      console.log("in checked...");
    
      if (vendor_sort) {
        SalesPerMonth.data.datasets = [];
        for (var device in months_by_year_devices_L[year - 2017]) {
          // [year{device:[month array]}]
          SalesPerMonth.data.datasets.push({
            label: device,
            data: months_by_year_devices_L[year  - 2017][device],
            backgroundColor: "#" + devices_info[device],
            //borderColor: "#" + devices_info[device],
            borderWidth: 3,
          });
        }
        bar_chart.update();
        //////
        for (var i in SalesPerMonth.data.datasets[0])
          console.log(
            "checked end__" + i + ": " + SalesPerMonth.data.datasets[0][i]
          );
        console.log(
          "meta: " + JSON.stringify(SalesPerMonth.data.datasets[0]["meta"])
        );
        ///////////
      } else {
        console.log("in unchecked...");
        SalesPerMonth.data.datasets = [
          {
            label: "Total Sales per month",
            data: months_by_year[year  - 2017],
            backgroundColor: "rgba(54,73,93,.5)",
            borderColor: "#36495d",
            borderWidth: 3,
          },
        ];
        bar_chart.update();
      };
    
  },

  methods: {
    extract_date_info(data) {
      data.forEach(function (arrayItem) {
        first_time_pinged_system.push(
          moment.unix(arrayItem.lastUpdated / 1000)
        );
      });
    },

    month_data_by_year(data) {
      var currentYear = moment().format("YYYY");
      var months_by_year = [];
      var years = currentYear - 2017 + 1;
      //create 2d array of months by year
      for (let index = 0; index < years; index++) {
        months_by_year.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      }
      data.forEach(function (arrayItem) {
        var index = arrayItem.format("YYYY") - 2017;
        months_by_year[index][arrayItem.format("M") - 1] += 1;
      });
      //console.log("mby2 " + months_by_year)
      return months_by_year;
    },
    //names and colors
    get_device_info(data) {
      var set = new Set();
      data.forEach(function (arrayItem) {
        var device = arrayItem.device;
        if (device == undefined) {
          device = "unknown";
        }
        set.add(device);
      });
      //create object with device and color
      var devices_info = {};
      set.forEach(function (device) {
        devices_info[device] = string.to_hex(device);
      });
      return devices_info;
    },
    month_data_by_year_by_device(data, devices_info) {
      var currentYear = moment().format("YYYY");
      var months_by_year = [];
      var years = currentYear - 2017 + 1;
      //create 2d array of months by year
      //year > device > data
      for (let i = 0; i < years; i++) {
        months_by_year[i] = {};
        //console.log("devinfo " + JSON.stringify(devices_info));
        for (var device in devices_info) {
          months_by_year[i][device] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
      }
      //console.log("harpia check " + months_by_year[0]["harpia"]);
      data.forEach(function (arrayItem) {
        if (arrayItem.device == undefined) {
          arrayItem.device = "unknown";
        }
        //console.log("devtest " + arrayItem.device)
        var date = moment.unix(arrayItem.lastUpdated / 1000);
        var index = date.format("YYYY") - 2017;
        var device = arrayItem.device;
        //console.log("itemtest " + arrayItem)
        //console.log("date: " + date + ' Device: ' + arrayItem.device + ' Month: ' + date.format("M"))
        //console.log("mby __ " + months_by_year)
        //console.log("index " + index + " arrayItem.device " + device);
        //console.log("devtest2 " + device)
        months_by_year[index][device][date.format("M") - 1] += 1;
        //console.log("mby_total " + months_by_year[index][device]);
        //console.log("devtest3 " + device)
      });
      //console.log("mby2 " + months_by_year[1]["potter"]);
      //console.log("mby_d_source " + months_by_year_devices)
      //console.log("motherload " + JSON.stringify(months_by_year[0]));
      return months_by_year;
    },
    create_years_list(){
     var current_year = (moment().format("YYYY"));
     var year = 2017;
     var result =[]
    while (year < current_year){
      result.push(year);
      year +=1;
    }
    result.push(year)
    return result;
  },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
