<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" width="300"/>
    <SalesPerMonth />
  </div>
</template>

<script>
// @ is an alias to /src
import SalesPerMonth from "@/components/SalesPerMonth.vue";

export default {
  name: "Home",
  components: {
    SalesPerMonth,
  },
};
</script>
